import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00387b',
      contrastText: '#fff',
    },
    secondary: {
      main: '#04859D',
      contrastText: '#fff',
    },
  },
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontFamily: [
      '"Heebo"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default theme;
